<template>
  <div class="container">
    <div class="col-md-12">
      <h1 class="title">Contactez-nous</h1>
    </div>
    <div class="p-5 alert-success">
      Votre demande a bien été prise en compte.
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContactValid'
});
</script>
