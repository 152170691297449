
import { defineComponent } from 'vue';

import RecapMessages from '@/components/Charts/RecapMessages.vue';
import LineChartMessages from '@/components/Charts/LineChartMessages.vue';

export default defineComponent({
  name: 'StatistiquesMrh',
  components: {
    RecapMessages,
    LineChartMessages
  },
  props: {
    service: {
      type: String,
      required: false
    },
    isLogged: {
      type: String,
      required: false
    },
    isTabDisabled: {
      type: String,
      required: false
    }
  }
});
