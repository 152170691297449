
import { defineComponent, onMounted, ref } from 'vue';

import marked from 'marked';
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
  name: 'DonneesPersoProtection',
  setup() {
    const protection = ref();
    const loading = ref(true);

    onMounted(async () => {
      const response = await fetch('../markdown/donnees/protection.md');
      const data = await response.text();
      protection.value = sanitizeHtml(marked(data));
      loading.value = false;
    });

    return { protection, loading };
  }
});
