import ApiAxway from '@/models/ApiAxway';

export function useUser() {
  const isApiExistsInOrganization = (apiName: string, userApis: ApiAxway[]): boolean => {
    if (!userApis) {
      return false;
    }
    const item = userApis.find((a) => a.name === apiName);
    return item != null;
  };

  return {
    isApiExistsInOrganization
  };
}
