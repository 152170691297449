
import { defineComponent, onMounted, ref } from 'vue';

import marked from 'marked';
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
  name: 'Cookies',
  setup() {
    const cookies = ref();
    const loading = ref(true);

    onMounted(async () => {
      const response = await fetch('../markdown/cookies/cookies.md');
      const data = await response.text();
      cookies.value = sanitizeHtml(marked(data));
      loading.value = false;
    });

    return { cookies, loading };
  }
});
