import { useAxwayService } from '@/services/useAxwayService';

export function useSwagger() {
  const { fetchAxwaySwagger } = useAxwayService();

  const fetchSwagger = async (service: string) => {
    let error = false;

    const { data, status } = await fetchAxwaySwagger(service);

    if (status === 500) {
      error = true;
    }

    return { data, error };
  };

  return {
    fetchSwagger
  };
}
