<template>
  <div class="container">
    <div class="col-md-12">
      <h1 class="title">Contactez-nous</h1>
    </div>

    <template v-if="error">
      <Message message="Impossible d'envoyer le message." type="warning"></Message>
    </template>

    <template v-if="validation">
      <Message message="Votre demande a bien été prise en compte." type="success"></Message>
    </template>

    <Form @submit="sendForm" v-slot="{ meta: formMeta }">
      <!-- Champs Nom/Email et Orga si user connecté -->
      <template v-if="isLogged">
        <Field v-model="user.name" name="nom" rules="required" v-slot="{ field, meta }">
          <div class="mb-3">
            <label
                for="nom"
                class="form-label"
                :class="{ 'text-danger': meta.dirty && !meta.valid }"
            >
              <span class="text-danger">*</span>
              Nom
            </label>
            <input
                id="nom"
                class="form-control"
                :class="{ 'is-invalid': meta.dirty && !meta.valid }"
                :disabled="user !== null"
                v-bind="field"
            />
            <ErrorMessage name="nom" class="invalid-feedback"/>
          </div>
        </Field>

        <Field v-model="user.email" name="email" rules="required|email" v-slot="{ field, meta }">
          <div class="mb-3">
            <label
                for="email"
                class="form-label"
                :class="{ 'text-danger': meta.dirty && !meta.valid }"
            >
              <span class="text-danger">*</span>
              Email
            </label>
            <input
                id="email"
                class="form-control"
                :class="{ 'is-invalid': meta.dirty && !meta.valid }"
                v-bind="field"
            />
            <ErrorMessage name="email" class="invalid-feedback"/>
          </div>
        </Field>

        <Field
            v-model="user.organization.name"
            name="societe"
            rules="required|min:2"
            v-slot="{ field, meta }"
        >
          <div class="mb-3">
            <label
                for="societe"
                class="form-label"
                :class="{ 'text-danger': meta.dirty && !meta.valid }"
            >
              <span class="text-danger">*</span>
              Société
            </label>
            <input
                id="societe"
                class="form-control"
                :class="{ 'is-invalid': meta.dirty && !meta.valid }"
                :disabled="user !== null"
                v-bind="field"
            />
            <ErrorMessage name="societe" class="invalid-feedback"/>
          </div>
        </Field>
      </template>

      <!-- Champs Nom/Email/Téléphone et Orga si user non connecté -->
      <template v-else>
        <Field name="nom" rules="required" v-slot="{ field, meta }">
          <div class="mb-3">
            <label
                for="nom"
                class="form-label"
                :class="{ 'text-danger': meta.dirty && !meta.valid }"
            >
              <span class="text-danger">*</span>
              Nom
            </label>
            <input
                id="nom"
                class="form-control"
                :class="{ 'is-invalid': meta.dirty && !meta.valid }"
                v-bind="field"
            />
            <ErrorMessage name="nom" class="invalid-feedback"/>
          </div>
        </Field>

        <Field name="email" rules="required|email" v-slot="{ field, meta }">
          <div class="mb-3">
            <label
                for="email"
                class="form-label"
                :class="{ 'text-danger': meta.dirty && !meta.valid }"
            >
              <span class="text-danger">*</span>
              Email
            </label>
            <input
                id="email"
                class="form-control"
                :class="{ 'is-invalid': meta.dirty && !meta.valid }"
                v-bind="field"
            />
            <ErrorMessage name="email" class="invalid-feedback"/>
          </div>
        </Field>

        <Field name="telephone" rules="required" v-slot="{ field, meta }">
          <div class="mb-3">
            <label
                for="telephone"
                class="form-label"
                :class="{ 'text-danger': meta.dirty && !meta.valid }"
            >
              <span class="text-danger">*</span>
              Téléphone
            </label>
            <input
                id="telephone"
                class="form-control"
                :class="{ 'is-invalid': meta.dirty && !meta.valid }"
                v-bind="field"
            />
            <ErrorMessage name="telephone" class="invalid-feedback"/>
          </div>
        </Field>

        <Field name="societe" rules="required|min:2" v-slot="{ field, meta }">
          <div class="mb-3">
            <label
                for="societe"
                class="form-label"
                :class="{ 'text-danger': meta.dirty && !meta.valid }"
            >
              <span class="text-danger">*</span>
              Société
            </label>
            <input
                id="societe"
                class="form-control"
                :class="{ 'is-invalid': meta.dirty && !meta.valid }"
                v-bind="field"
            />
            <ErrorMessage name="societe" class="invalid-feedback"/>
          </div>
        </Field>
      </template>

      <Field v-model="selectedType" name="type" rules="required" v-slot="{ field, meta }">
        <div class="mt-3 mb-3">
          <label
              for="type"
              class="form-label"
              :class="{ 'text-danger': meta.dirty && !meta.valid }"
          >
            <span class="text-danger">*</span>
            Votre demande concerne
          </label>
          <select
              id="type"
              v-bind="field"
              class="form-control form-select"
              :class="{ 'is-invalid': meta.dirty && !meta.valid }"
          >
            <option v-for="option in optionsType" :value="option.value" :key="option.value">
              {{ option.text }}
            </option>
            <option v-if="user" value="Support technique">Support technique</option>
          </select>
        </div>
      </Field>

      <Field
          v-model="selectedTypeSupport"
          v-if="selectedType === 'Support technique'"
          name="support"
          rules="required"
          v-slot="{ field, meta }"
      >
        <div class="mt-3 mb-3">
          <label
              for="support"
              class="form-label"
              :class="{ 'text-danger': meta.dirty && !meta.valid }"
          >
            <span class="text-danger">*</span>
            Type de support
          </label>
          <select
              id="support"
              v-bind="field"
              class="form-control form-select"
              :class="{ 'is-invalid': meta.dirty && !meta.valid }"
          >
            <option v-for="option in optionTypeSupport" :value="option.value" :key="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
      </Field>

      <Field
          v-slot="{ field, meta }"
          v-if="selectedType === 'Support technique'"
          name="blocage"
          rules="required"
      >
        <label
            for="blocage"
            class="form-label"
            :class="{ 'text-danger': meta.dirty && !meta.valid }"
        >
          <span class="text-danger">*</span>
          Est-ce bloquant pour votre activité ?
        </label>
        <div class="mr-auto me-3 radio-toolbar">
          <div class="radio">
            <input id="oui" name="blocage" v-bind="field" type="radio" value="Oui"/>
            <label for="oui" class="me-3">Oui</label>
          </div>

          <div class="radio">
            <input id="non" name="blocage" v-bind="field" type="radio" value="Non"/>
            <label for="non" class="me-3">Non</label>
          </div>
        </div>
      </Field>

      <Field name="produit" rules="required" v-slot="{ field, meta }">
        <div class="mt-3 mb-3">
          <label
              for="produit"
              class="form-label"
              :class="{ 'text-danger': meta.dirty && !meta.valid }"
          >
            <span class="text-danger">*</span>
            Produit
          </label>
          <select
              v-bind="field"
              class="form-control form-select"
              :class="{ 'is-invalid': meta.dirty && !meta.valid }"
              id="produit"
          >
            <option v-for="produit in optionsProduct" :value="produit" :key="produit">
              {{ produit }}
            </option>
          </select>
        </div>
      </Field>

      <Field name="message" v-slot="{ field, meta }" rules="required">
        <div class="mb-3">
          <label
              for="message"
              class="form-label"
              :class="{ 'text-danger': meta.dirty && !meta.valid }"
          >
            <span class="text-danger">*</span>
            Message
          </label>
          <textarea
              id="message"
              class="form-control"
              :class="{ 'is-invalid': meta.dirty && !meta.valid }"
              v-bind="field"
          />
          <ErrorMessage name="message" class="invalid-feedback"/>
        </div>
      </Field>

      <div class="small mt-auto mb-3">
        <em>
          <span class="text-danger">*</span>
          Champs obligatoires
        </em>
      </div>

      <button class="btn button-primary" type="submit" :disabled="!formMeta.valid">Envoyer</button>

      <div class="small mt-2 mb-3 text-bleu-groupama" v-if="validationOk">
        <em>Votre demande a bien été transmise</em>
      </div>
    </Form>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';

import Message from '@/components/Static/Message.vue';
import {ErrorMessage, Field, Form} from 'vee-validate';
import {useContactService} from '@/services/useContactService';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from '@/store/store';
import {useReCaptcha} from 'vue-recaptcha-v3';

import apis from '@/assets/apis.json';
import '@/formsRules';

export default defineComponent({
  name: 'Contact',
  components: {
    ErrorMessage,
    Field,
    Form,
    Message
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {isLogged, user} = useStore();
    const {sendToJira} = useContactService();
    const reCaptcha = useReCaptcha();

    const validation = ref(false);
    let error = ref(false);
    let selectedType = ref('');
    let optionsProduct = ref<string[]>();
    const optionsType = [
      {text: 'Question', value: 'Question'},
      {text: 'Remarque', value: 'Remarque'},
      {text: 'Suggestion', value: 'Suggestion'},
      {text: 'Partenariat', value: 'Partenariat'},
      {text: 'Autre', value: 'Autre'}
    ];
    const optionsSupportType = [
      {text: 'Technique', value: 'Technique'},
      {text: 'Fonctionnel', value: 'Fonctionnel'}
    ];

    onMounted(async () => {
      window.scroll(1, 1);
      optionsProduct.value = apis.map((a) => a.title);

      if (route.params.partenariat === 'partenariat') {
        selectedType.value = 'Partenariat';
      }

      await reCaptcha?.recaptchaLoaded();
      reCaptcha?.instance.value?.showBadge();
    });

    onUnmounted(() => {
      reCaptcha?.instance.value?.hideBadge();
    });

    const sendContactToJira = async (token: string, values: any, {resetForm}: any) => {
      validation.value = false;
      error.value = false;

      let support = '';
      let phone = '';
      if (user != null && selectedType.value === 'Support technique') {
        support = `*Type de support :* ${values.support}\n*Bloquant :* ${values.blocage}`;
      }
      if (user == null) {
        phone = `*Téléphone :* ${values.telephone}`;
      }

      const title = `[Portail API] ${values.type}`;
      const description = `*Nom :* ${values.nom}\n*Email :* ${values.email}\n*Société :* ${values.societe}\n*Produit :* ${values.produit}\n*Message :* ${values.message}\n${phone}\n${support}`;

      const responseJira = await sendToJira(title, description, token);

      if (responseJira.ok) {
        validation.value = true;
        //resetForm();
        await router.push('/contact-valid');
      } else {
        error.value = true;
      }
    };

    const sendForm = async (values: any, {resetForm}: any) => {
      await reCaptcha!.recaptchaLoaded();
      const token = await reCaptcha!.executeRecaptcha('login');

      await sendContactToJira(token, values, resetForm);
    };

    return {
      user,
      isLogged,
      sendForm,
      validation,
      error,
      selectedType,
      optionsProduct,
      optionsType,
      optionsSupportType,
      route
    };
  }
});
</script>
