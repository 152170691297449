<template>
  <div
    class="alert"
    :class="{
      'alert-danger': type === 'warning',
      'alert-success': type === 'success',
      'alert-info': type === 'info'
    }"
    role="alert"
  >
    {{ message }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Message',
  props: {
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  }
});
</script>
