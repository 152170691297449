
import { defineComponent, watch, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store/store';
import { useUser } from '@/composables/useUser';
import Api from '@/models/Api';
import router from '@/router';

import Presentation from '@/components/Api/Presentation.vue';
import Documentation from '@/components/Api/Documentation.vue';
import Statistiques from '@/components/Api/Statistiques.vue';

import apis from '@/assets/apis.json';
import ApiAxway from '@/models/ApiAxway';

export default defineComponent({
  name: 'Api',
  components: {
    Presentation,
    Documentation,
    Statistiques
  },
  setup() {
    const { isLogged, user } = useStore();
    const { isApiExistsInOrganization } = useUser();
    const route = useRoute();

    let apiName = ref(route.params.api);
    let api = ref<Api | null>(null);
    let apiInOrga = ref(false);
    const isTabDisabled = ref(false);

    onMounted(() => {
      loadApiInfos(route.params.api as string);

      document.getElementById('presentation-tab')?.classList.remove('active');
      document.getElementById('documentation-tab')?.classList.remove('active');
      document.getElementById('statistiques-tab')?.classList.remove('active');
      document.getElementById('presentation')?.classList.remove('active');
      document.getElementById('documentation')?.classList.remove('active');
      document.getElementById('statistiques')?.classList.remove('active');

      document.getElementById('presentation-tab')?.classList.add('active');
      document.getElementById('presentation')?.classList.add('active');
    });

    const loadApiInfos = (apiName: string) => {
      const searchApi = apis.filter((x) => x.name === apiName)[0];
      if (searchApi) {
        api.value = searchApi;
        apiInOrga.value = isApiExistsInOrganization(
          api.value.axwayName,
          user.value?.organization.apis as ApiAxway[]
        );
        isTabDisabled.value = isLogged && !apiInOrga.value;
      } else {
        router.push('/catalogue');
      }
    };

    watch(
      () => route.params.api,
      (param) => {
        if (param !== undefined) {
          api.value = null;
          setTimeout(() => {
            apiName.value = param;
            loadApiInfos(apiName.value as string);
          });
        }
      }
    );

    return {
      isLogged,
      api,
      apiInOrga,
      isTabDisabled
    };
  }
});
