
import { defineComponent, ref } from 'vue';

import marked from 'marked';
import sanitizeHtml from 'sanitize-html';
import { useGitlabService } from '@/services/useGitlabService';
import Message from '@/components/Static/Message.vue';

export default defineComponent({
  name: 'Presentation',
  components: {
    Message
  },
  props: {
    name: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const gitlabService = useGitlabService();
    const logo = ref();
    const readme = ref();
    const error = ref(false);

    const b64DecodeUnicode = (str: string) => {
      return decodeURIComponent(
        Array.prototype.map
          .call(atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
    };

    try {
      const logoGitlab = await gitlabService.fetchFile(props.name, 'logo');
      const logoGitlabValue = await logoGitlab.text();
      logo.value = logoGitlabValue;

      const readmeGitlab = await gitlabService.fetchFile(props.name, 'readme');
      const readmeGitlabValue = await readmeGitlab.text();
      readme.value = marked(sanitizeHtml(b64DecodeUnicode(readmeGitlabValue)));
    } catch (_) {
      error.value = true;
    }

    return { logo, readme, error };
  }
});
