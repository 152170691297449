<template>
  <div class="page-container">
    <Navbar />
    <div class="page-content"><router-view></router-view></div>
    <Footer />

    <cookies-law></cookies-law>
    <template v-if="route.path !== '/contact'">
      <router-link to="/contact" class="contact-float-button">
        Une question
        <font-awesome-icon icon="question-circle" />
      </router-link>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import Navbar from '@/components/Static/Navbar.vue';
import Footer from '@/components/Static/Footer.vue';
import CookiesLaw from '@/components/Static/CookiesLaw.vue';

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    Footer,
    CookiesLaw
  },
  setup() {
    const route = useRoute();

    return { route };
  }
});
</script>

<style lang="scss">
@import '@/styles/main';
</style>
