<template>
  <div class="container">
    <!-- Récap jour/mois/année -->
    <div class="col-md-12 stats-section">
      <h1>Récapitulatif</h1>
      <div class="row">
        <div class="col-md-12">
          <template v-if="isLogged && !isTabDisabled">
            <RecapMessages
              :service="service"
              :is-logged="isLogged"
              :is-tab-disabled="isTabDisabled"
            />
          </template>
        </div>
      </div>
    </div>

    <!-- Linechart entre deux dates -->
    <div class="col-md-12 stats-section">
      <h1>Vos consommations</h1>
      <div class="row">
        <div class="col-md-12">
          <template v-if="isLogged && !isTabDisabled">
            <LineChartMessages
              :service="service"
              :is-logged="isLogged"
              :is-tab-disabled="isTabDisabled"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import RecapMessages from '@/components/Charts/RecapMessages.vue';
import LineChartMessages from '@/components/Charts/LineChartMessages.vue';

export default defineComponent({
  name: 'StatistiquesMrh',
  components: {
    RecapMessages,
    LineChartMessages
  },
  props: {
    service: {
      type: String,
      required: false
    },
    isLogged: {
      type: String,
      required: false
    },
    isTabDisabled: {
      type: String,
      required: false
    }
  }
});
</script>
