
import { defineComponent } from 'vue';

import { useStore } from '@/store/store';

export default defineComponent({
  name: 'Home',
  setup() {
    const { isLogged } = useStore();

    return { isLogged };
  }
});
