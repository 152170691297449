<template>
  <template v-if="error">
    <Message message="Impossible de charger les statistiques." type="warning"></Message>
  </template>

  <template v-else>
    <div class="datepickers-container">
      <p>Sélectionnez une date de début et une date de fin :</p>
      <div class="datepickers">
        <!-- Date de début -->
        <v-date-picker
          class="datepickers-item"
          v-model="selectedBeginDate"
          :popover="{ visibility: 'click' }"
          @dayclick="reloadDataBeginDate"
        >
          <template #default="{ inputValue, inputEvents }">
            <input
              class="bg-white border px-2 py-1 rounded"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </v-date-picker>

        <font-awesome-icon class="datepickers-item" icon="arrow-right" />

        <!-- Date de fin -->
        <v-date-picker
          class="datepickers-item"
          v-model="selectedEndDate"
          :popover="{ visibility: 'click' }"
          @dayclick="reloadDataEndDate"
        >
          <template #default="{ inputValue, inputEvents }">
            <input
              class="bg-white border px-2 py-1 rounded"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </v-date-picker>
      </div>
    </div>

    <apexchart
      v-if="isLogged && !isTabDisabled"
      id="chart"
      type="line"
      height="600"
      :options="chartOptions"
      :series="chartSeries"
    ></apexchart>
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue';

import Message from '@/components/Static/Message.vue';
import { useChart } from '@/composables/useChart';
import StatsAxway from '@/models/StatsAxway';

export default defineComponent({
  name: 'NumberMessages',
  components: {
    Message
  },
  props: {
    service: {
      type: String,
      required: true
    },
    isLogged: {
      type: String,
      required: true
    },
    isTabDisabled: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const {
      fetchDataChart,
      generateLineChartCategories,
      formatDateToString,
      generateBeginDate,
      generateEndDate
    } = useChart();

    const state = reactive({
      chartOptions: {},
      chartSeries: {},
      selectedBeginDate: '',
      selectedEndDate: '',
      error: false
    });

    onMounted(async () => {
      state.selectedBeginDate = formatDateToString(new Date());
      state.selectedEndDate = formatDateToString(new Date());
      loadData();
    });

    const loadData = async () => {
      const { data, error } = await fetchDataChart(
        generateBeginDate(state.selectedBeginDate),
        generateEndDate(state.selectedEndDate),
        props.service
      );
      state.error = error;

      const categories = generateLineChartCategories(data);
      loadLineChart(data, categories);
    };

    const reloadDataBeginDate = async (event: any) => {
      state.error = false;
      state.selectedBeginDate = event.id;

      const { data, error } = await fetchDataChart(
        generateBeginDate(state.selectedBeginDate),
        generateEndDate(state.selectedEndDate),
        props.service
      );
      state.error = error;
      const categories = generateLineChartCategories(data);
      loadLineChart(data, categories);
    };

    const reloadDataEndDate = async (event: any) => {
      state.error = false;
      state.selectedEndDate = event.id;

      const { data, error } = await fetchDataChart(
        generateBeginDate(state.selectedBeginDate),
        generateEndDate(state.selectedEndDate),
        props.service
      );
      state.error = error;
      const categories = generateLineChartCategories(data);
      loadLineChart(data, categories);
    };

    const axisFormatter = (interval: number, timestamp: number): string => {
      const date = new Date(timestamp);
      switch (interval) {
        case 3600000:
          return `Le ${date.getDate()}/${date.getMonth() + 1} à ${date.getHours()}:00`;
        case 86400000:
          return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        case 604800000:
          return `Semaine du ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        default:
          return '';
      }
    };

    const loadLineChart = (data: StatsAxway, categories: number[]) => {
      state.chartOptions = {
        colors: ['#1c6955'],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: "Nombre d'appels entre deux dates",
          align: 'center',
          style: {
            color: '#1c6955'
          }
        },
        xaxis: {
          categories: categories,
          type: 'datetime',
          tickAmount: 20,
          labels: {
            formatter: function (timestamp: number) {
              return axisFormatter(data.interval, timestamp);
            }
          }
        },
        yaxis: {
          title: {
            text: "Nombre d'appels",
            style: {
              fontSize: '0.9rem',
              color: '#1c6955'
            }
          }
        }
      };

      state.chartSeries = [
        {
          name: "Nombre d'appels",
          data: data.values
        }
      ];
    };

    return { ...toRefs(state), reloadDataBeginDate, reloadDataEndDate };
  }
});
</script>
