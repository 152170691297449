<template>
  <div class="cookies" v-if="!acceptedCookies">
    <div class="cookies-title"><p><font-awesome-icon icon="cookie-bite" /> Cookies</p></div>
    <div class="cookies-description">Ce site utilise uniquement des cookies techniques.</div>
    <div class="cookies-buttons">
      <button class="cookies-button-ok" @click="storeAcceptedCookies">Compris !</button>
      <router-link to="/mentions-legales"><button class="cookies-button-learn">En savoir plus</button></router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useStore } from '@/store/store';

export default defineComponent({
  name: 'CookieLaw',
  setup() {
    const { acceptedCookies, storeAcceptedCookies } = useStore();

    return { acceptedCookies, storeAcceptedCookies };
  }
});
</script>
