
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import Navbar from '@/components/Static/Navbar.vue';
import Footer from '@/components/Static/Footer.vue';
import CookiesLaw from '@/components/Static/CookiesLaw.vue';

export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    Footer,
    CookiesLaw
  },
  setup() {
    const route = useRoute();

    return { route };
  }
});
