
import {defineComponent, onMounted, onUnmounted, reactive, ref} from 'vue';
import router from '@/router';

import { useField, useForm } from 'vee-validate';
import { useUserService } from '@/services/useUserService';
import { useReCaptcha } from 'vue-recaptcha-v3';
import Message from '@/components/Static/Message.vue';
import '@/formsRules';

export default defineComponent({
  name: 'Login',
  components: {
    Message
  },
  setup() {
    const { loginUser } = useUserService();
    const reCaptcha = useReCaptcha();

    const error = ref(false);
    const loading = ref(false);

    const { meta: formMeta, values: formValues } = useForm();
    const username = reactive(useField('username', { required: true }));
    const password = reactive(useField('password', { required: true }));

    onMounted(async () => {
      await reCaptcha?.recaptchaLoaded();
      reCaptcha?.instance.value?.showBadge();
    });

    onUnmounted(() => {
      reCaptcha?.instance.value?.hideBadge();
    });

    async function login() {
      error.value = false;
      loading.value = true;

      await reCaptcha!.recaptchaLoaded();
      const token = await reCaptcha!.executeRecaptcha('login');

      const errorLogin = await loginUser(formValues.username, formValues.password, token);

      if (!errorLogin) {
        router.push('/');
      } else {
        error.value = true;
      }

      loading.value = false;
    }

    return { username, password, formMeta, login, error, loading };
  }
});
