<template>
  <div class="container" v-if="api">
    <div class="col-md-12">
      <h1 class="title">API {{ api.title }}</h1>
    </div>

    <ul class="nav nav-tabs nav-justified navigation-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="presentation-tab"
          data-bs-toggle="tab"
          data-bs-target="#presentation"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          <font-awesome-icon icon="home" />
          Présentation
        </button>
      </li>

      <!-- Onglet Documentation isLogged -->
      <li class="nav-item" role="presentation" v-if="isLogged && !isTabDisabled">
        <button
          class="nav-link"
          id="documentation-tab"
          data-bs-toggle="tab"
          data-bs-target="#documentation"
          type="button"
          role="tab"
          aria-controls="documentation"
          aria-selected="false"
        >
          <font-awesome-icon icon="book" />
          Documentation
        </button>
      </li>

      <!-- Onglet Documentation isTabDisabled -->
      <li class="nav-item" role="presentation" v-if="isLogged && isTabDisabled">
        <div
          data-toggle="tooltip"
          data-placement="top"
          title="Votre organisation n'est pas rattachée à l'API."
        >
          <button
            class="nav-link disabled"
            id="documentation-tab"
            data-bs-toggle="tab"
            data-bs-target="#documentation"
            type="button"
            role="tab"
            aria-controls="documentation"
            aria-selected="false"
          >
            <font-awesome-icon icon="lock" />
            Documentation
          </button>
        </div>
      </li>

      <!-- Onglet Documentation !isLogged -->
      <li class="nav-item" role="presentation" v-if="!isLogged">
        <div data-toggle="tooltip" data-placement="top" title="Vous devez être connecté.">
          <button
            class="nav-link disabled"
            id="documentation-tab"
            data-bs-toggle="tab"
            data-bs-target="#documentation"
            type="button"
            role="tab"
            aria-controls="documentation"
            aria-selected="false"
          >
            <font-awesome-icon icon="lock" />
            Documentation
          </button>
        </div>
      </li>

      <!-- Onglet Statistiques isLogged -->
      <li class="nav-item" role="presentation" v-if="isLogged && !isTabDisabled">
        <button
          class="nav-link"
          id="statistiques-tab"
          data-bs-toggle="tab"
          data-bs-target="#statistiques"
          type="button"
          role="tab"
          aria-controls="statistiques"
          aria-selected="false"
        >
          <font-awesome-icon icon="chart-line" />
          Statistiques
        </button>
      </li>

      <!-- Onglet Statistiques isTabDisabled -->
      <li class="nav-item" role="presentation" v-if="isLogged && isTabDisabled">
        <div
          data-toggle="tooltip"
          data-placement="top"
          title="Votre organisation n'est pas rattachée à l'API."
        >
          <button
            class="nav-link disabled"
            id="statistiques-tab"
            data-bs-toggle="tab"
            data-bs-target="#statistiques"
            type="button"
            role="tab"
            aria-controls="statistiques"
            aria-selected="false"
          >
            <font-awesome-icon icon="lock" />
            Statistiques
          </button>
        </div>
      </li>

      <!-- Onglet Statistiques !isLogged -->
      <li class="nav-item" role="presentation" v-if="!isLogged">
        <div data-toggle="tooltip" data-placement="top" title="Vous devez être connecté.">
          <button
            class="nav-link disabled"
            id="statistiques-tab"
            data-bs-toggle="tab"
            data-bs-target="#statistiques"
            type="button"
            role="tab"
            aria-controls="statistiques"
            aria-selected="false"
          >
            <font-awesome-icon icon="lock" />
            Statistiques
          </button>
        </div>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="presentation"
        role="tabpanel"
        aria-labelledby="presentation-tab"
      >
        <Suspense>
          <template #default>
            <Presentation :name="api.name" />
          </template>
          <template #fallback>
            <div class="text-center">
              <div class="spinner-border spinner-custom" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
        </Suspense>
      </div>

      <div
        class="tab-pane fade"
        id="documentation"
        role="tabpanel"
        aria-labelledby="documentation-tab"
        v-if="isLogged && !isTabDisabled"
      >
        <Documentation :api="api" />
      </div>

      <div
        class="tab-pane fade"
        id="statistiques"
        role="tabpanel"
        aria-labelledby="statistiques-tab"
        v-if="isLogged && !isTabDisabled"
      >
        <Suspense>
          <template #default>
            <Statistiques
              :service="api.axwayName"
              :is-logged="isLogged"
              :is-tab-disabled="isTabDisabled"
            />
          </template>
          <template #fallback>
            <div class="text-center">
              <div class="spinner-border spinner-custom" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </template>
        </Suspense>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store/store';
import { useUser } from '@/composables/useUser';
import Api from '@/models/Api';
import router from '@/router';

import Presentation from '@/components/Api/Presentation.vue';
import Documentation from '@/components/Api/Documentation.vue';
import Statistiques from '@/components/Api/Statistiques.vue';

import apis from '@/assets/apis.json';
import ApiAxway from '@/models/ApiAxway';

export default defineComponent({
  name: 'Api',
  components: {
    Presentation,
    Documentation,
    Statistiques
  },
  setup() {
    const { isLogged, user } = useStore();
    const { isApiExistsInOrganization } = useUser();
    const route = useRoute();

    let apiName = ref(route.params.api);
    let api = ref<Api | null>(null);
    let apiInOrga = ref(false);
    const isTabDisabled = ref(false);

    onMounted(() => {
      loadApiInfos(route.params.api as string);

      document.getElementById('presentation-tab')?.classList.remove('active');
      document.getElementById('documentation-tab')?.classList.remove('active');
      document.getElementById('statistiques-tab')?.classList.remove('active');
      document.getElementById('presentation')?.classList.remove('active');
      document.getElementById('documentation')?.classList.remove('active');
      document.getElementById('statistiques')?.classList.remove('active');

      document.getElementById('presentation-tab')?.classList.add('active');
      document.getElementById('presentation')?.classList.add('active');
    });

    const loadApiInfos = (apiName: string) => {
      const searchApi = apis.filter((x) => x.name === apiName)[0];
      if (searchApi) {
        api.value = searchApi;
        apiInOrga.value = isApiExistsInOrganization(
          api.value.axwayName,
          user.value?.organization.apis as ApiAxway[]
        );
        isTabDisabled.value = isLogged && !apiInOrga.value;
      } else {
        router.push('/catalogue');
      }
    };

    watch(
      () => route.params.api,
      (param) => {
        if (param !== undefined) {
          api.value = null;
          setTimeout(() => {
            apiName.value = param;
            loadApiInfos(apiName.value as string);
          });
        }
      }
    );

    return {
      isLogged,
      api,
      apiInOrga,
      isTabDisabled
    };
  }
});
</script>
