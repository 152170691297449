import { useStore } from '@/store/store';
import axios from 'axios';

export function useUserService() {
  const { storeUser, deleteUser } = useStore();

  const loginUser = async (username: string, password: string, token: string) => {
    let error = false;

    // Récupération d'un cookie Axway
    try {
      const body = { username, password, token };
      const headers = { 'content-type': 'application/json' };
      const { data: cookie } = await axios.post('/rest/users/login', body, { headers });

      // Récupération des infos de l'utilisateur et ajout dans le store
      try {
        const headers = { userCookie: cookie };
        const { data: user } = await axios.get('rest/users', { headers });

        user.cookie = cookie;
        storeUser(user);
      } catch (_) {
        error = true;
      }
    } catch (_) {
      error = true;
    }

    return error;
  };

  const logoutUser = () => {
    // On supprime l'utilisateur dans le store
    deleteUser();
  };

  return {
    loginUser,
    logoutUser
  };
}
