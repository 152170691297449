<template>
  <div class="container">
    <h1 class="title">Mentions légales</h1>

    <template v-if="loading">
      <div class="text-center">
        <div class="spinner-border spinner-custom" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </template>

    <template v-else>
      <!-- eslint-disable vue/no-v-html -->
      <div class="markdown-body" v-html="mentions"></div>
      <!--eslint-enable-->
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import marked from 'marked';
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
  name: 'Documentation',
  setup() {
    const mentions = ref();
    const loading = ref(true);

    onMounted(async () => {
      const response = await fetch('../markdown/mentions/mentions.md');
      const data = await response.text();
      mentions.value = sanitizeHtml(marked(data));
      loading.value = false;
    });

    return { mentions, loading };
  }
});
</script>
