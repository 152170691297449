<template>
  <div class="homepage-hero">
    <div>
      <Particles
        id="tsparticles"
        :particles-init="particlesInit"
        :particles-loaded="particlesLoaded"
        :options="{
          fpsLimit: 60,
          particles: {
            links: {
              enable: true,
              distance: 180,
              opacity: 0.2
            },
            move: {
              enable: true,
              speed: 1
            },
            number: {
              value: 120
            },
            opacity: {
              value: 0.3
            },
            size: {
              random: true,
              value: 5
            }
          },
          detectRetina: true
        }"
      />
    </div>

    <div class="container">
      <img class="homepage-logo" src="@/assets/images/groupama-homepage2.svg" />

      <div class="homepage-hero-content">
        <div class="homepage-hero-content-container">
          <p>Vous accompagne pour booster votre</p>

          <div class="homepage-hero-slideshow">
            <ul>
              <li>business</li>
              <li>activité</li>
              <li>développement</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="homepage-items">
        <h1>Comment ça fonctionne ?</h1>
        <div class="homepage-items-container">
          <div class="flex">
            <div class="homepage-item">
              <router-link class="catalog-item-link" to="/catalogue">
                <img src="@/assets/images/decouvrez-homepage.svg" />
                <h2 class="homepage-items-title">Découvrez</h2>
                <h3 class="homepage-items-subtitle">
                  le catalogue d'API à travers une présentation simple et intuivite
                </h3>
              </router-link>
            </div>

            <div class="homepage-item">
              <router-link class="catalog-item-link" to="/documentation">
                <img src="@/assets/images/comprenez-homepage.svg" />
                <h2 class="homepage-items-title">Comprenez</h2>
                <h3 class="homepage-items-subtitle">
                  leur utilisation à travers une documentation accessible à toutes et tous
                </h3>
              </router-link>
            </div>

            <div class="homepage-item" v-if="!isLogged">
              <router-link class="catalog-item-link" to="/contact/partenariat">
                <img src="@/assets/images/inscription-homepage.svg" />
                <h2 class="homepage-items-title">Inscrivez-vous</h2>
                <h3 class="homepage-items-subtitle">pour devenir partenaire privilégie de l'API</h3>
              </router-link>
            </div>

            <div class="homepage-item">
              <router-link class="catalog-item-link" to="/contact">
                <img src="@/assets/images/contact-homepage.svg" />
                <h2 class="homepage-items-title">Contactez-nous</h2>
                <h3 class="homepage-items-subtitle">pour en savoir plus ou obtenir de l'aide</h3>
              </router-link>
            </div>

            <div class="homepage-item">
              <a
                class="catalog-item-link open-modal"
                data-bs-toggle="modal"
                data-bs-target="#statsModal"
              >
                <img src="@/assets/images/simulation-homepage.svg" />
                <h2 class="homepage-items-title">Suivez</h2>
                <h3 class="homepage-items-subtitle">vos consommations API</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal hide fade"
    id="statsModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="statsModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="statsModalLongTitle">Consommations</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Pour chaque API à laquelle votre organisation est rattachée, rendez-vous dans l'onglet
          "Statistiques" pour suivre le nombre d'appels réalisés par votre organisation.

          <center><img class="modal-img" src="@/assets/images/stats_modal.png" /></center>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn button-primary" data-bs-dismiss="modal">Fermer</button>
        </div>
      </div>
    </div>
  </div>

  <div class="homepage-domains container">
    <h1>Notre offre</h1>
    <div class="flex">
      <div class="homepage-domain">
        <router-link class="catalog-offer-link" to="/service/habitation">
          <img src="@/assets/images/foyer-homepage.svg" />
          <p>Habitation</p>
        </router-link>
      </div>

      <div class="homepage-domain">
        <router-link class="catalog-offer-link" to="/service/auto">
          <img src="@/assets/images/mobilite-homepage.svg" />
          <p>Mobilité</p>
        </router-link>
      </div>

      <div class="homepage-domain">
        <router-link class="catalog-offer-link" to="/service/sante">
          <img src="@/assets/images/sante-homepage.svg" />
          <p>Santé</p>
        </router-link>
      </div>

      <div class="homepage-domain">
        <router-link class="catalog-offer-link" to="/service/lead">
          <img src="@/assets/images/business-homepage.svg" />
          <p>Lead</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useStore } from '@/store/store';

export default defineComponent({
  name: 'Home',
  setup() {
    const { isLogged } = useStore();

    return { isLogged };
  }
});
</script>
