<template>
  <footer class="footer fond-vert-groupama">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3 d-flex flex-column mb-md-0 me-3">
          <p><strong>Groupama</strong></p>
          <p class="footer-slogan">
            <q>
              Nous sommes là pour permettre au plus grand nombre de construire leur vie en
              confiance.
            </q>
          </p>
        </div>

        <div class="col-12 col-md-2 d-flex flex-column mb-md-0 me-3"></div>

        <div class="col-md-3 col-12 d-flex flex-column mb-5">
          <p><strong>Catalogue APIs</strong></p>
          <span>
            <RouterLink class="footer-lien" to="/service/habitation">API Habitation</RouterLink>
          </span>
          <span><RouterLink class="footer-lien" to="/service/auto">API Auto</RouterLink></span>
          <span><RouterLink class="footer-lien" to="/service/lead">API Lead</RouterLink></span>
          <span><RouterLink class="footer-lien" to="/service/sante">API Santé</RouterLink></span>
        </div>

        <div class="col-md-3 col-12 d-flex flex-column">
          <p><strong>Nos informations</strong></p>
          <span><RouterLink class="footer-lien" to="/contact">Contact</RouterLink></span>
          <span>
            <RouterLink class="footer-lien" to="/documentation">Documentation</RouterLink>
          </span>
          <span>
            <RouterLink class="footer-lien" to="/mentions-legales">Mentions légales</RouterLink>
          </span>
          <span>
            <RouterLink class="footer-lien" to="/donnees-personnelles">
              Données personnelles
            </RouterLink>
          </span>
          <span><RouterLink class="footer-lien" to="/cookies">Cookies</RouterLink></span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer'
});
</script>
