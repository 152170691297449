import { reactive, toRefs, ref } from 'vue';

import User from '@/models/User';

const state = reactive({
  user: ref(getUser()),
  isLogged: ref(getIsLogged()),
  acceptedCookies: ref(getAcceptedCookies())
});

export function getUser(): User | null {
  const user = window.localStorage.getItem('user');
  return user ? JSON.parse(user) : null;
}

export function getIsLogged(): boolean {
  const isLogged = window.localStorage.getItem('isLogged');
  return isLogged ? JSON.parse(isLogged) : null;
}

export function storeUser(userToStore: User): void {
  state.user = userToStore;
  state.isLogged = true;
  window.localStorage.setItem('user', JSON.stringify(userToStore));
  window.localStorage.setItem('isLogged', 'true');
}

export function deleteUser(): void {
  state.user = null;
  state.isLogged = false;
  window.localStorage.removeItem('user');
  window.localStorage.setItem('isLogged', 'false');
}

export function getAcceptedCookies(): boolean {
  const acceptedCookies = getCookie("acceptedCookies");
  return acceptedCookies ? JSON.parse(acceptedCookies) : null;
}

export function storeAcceptedCookies(): void {
  state.acceptedCookies = true;
  document.cookie = `acceptedCookies=true; expired ${new Date(new Date().setDate(new Date().getDate() + 7))}`;
}

function getCookie(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function useStore() {
  return {
    ...toRefs(state),
    storeUser,
    deleteUser,
    storeAcceptedCookies
  };
}
