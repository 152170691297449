
import { defineComponent, ref } from 'vue';

import CatalogCard from '@/components/Api/CatalogCard.vue';
import Api from '@/models/Api';
import services from '@/assets/apis.json';

export default defineComponent({
  name: 'Catalogue',
  components: {
    CatalogCard
  },
  setup() {
    const apis = ref<Api[] | null>(null);

    apis.value = services;

    return { apis };
  }
});
