<template>
  <nav class="navbar navbar-expand-lg navbar-light shadow fixed-top">
    <div class="container-fluid">
      <RouterLink class="nav-link" :to="{ name: 'home' }">
        <img src="@/assets/images/groupama.svg" height="50" alt="" />
      </RouterLink>

      <button class="navbar-toggler" @click="toggleNavbar" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse" :class="{ collapse: navbarCollapsed }">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <RouterLink
              to="/catalogue"
              :class="{
                'nav-link active': isCurrentRoute('catalogue'),
                'nav-link': !isCurrentRoute('catalogue')
              }"
            >
              Catalogue APIs
            </RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink
              to="/documentation"
              :class="{
                'nav-link active': isCurrentRoute('documentation'),
                'nav-link': !isCurrentRoute('documentation')
              }"
            >
              Documentation
            </RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink
              to="/contact"
              :class="{
                'nav-link active': isCurrentRoute('contact'),
                'nav-link': !isCurrentRoute('contact')
              }"
            >
              Contact
            </RouterLink>
          </li>
        </ul>

        <template v-if="isLogged">
          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenu"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <font-awesome-icon icon="user" />
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu">
              <li class="dropdown-item custom-item">{{ user.name }}</li>
              <li class="dropdown-item custom-item">
                <font-awesome-icon icon="id-card" />
                {{ user.role }}
              </li>
              <li class="dropdown-item custom-item">
                <font-awesome-icon icon="building" />
                {{ user.organization.name }}
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li class="dropdown-item custom-item" @click="logout">
                <button type="button" class="btn btn-logout button-primary">
                  <font-awesome-icon icon="sign-out-alt" />
                  Déconnexion
                </button>
              </li>
            </ul>
          </div>
        </template>
        <template v-else>
          <RouterLink class="nav-link" to="/connexion">
            <button type="button" class="btn btn-login button-primary">Connexion</button>
          </RouterLink>
        </template>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import router from '@/router';
import { useStore } from '@/store/store';
import { useRouter } from 'vue-router';
import { useUserService } from '@/services/useUserService';

export default defineComponent({
  name: 'Navbar',
  setup() {
    const { isLogged, user } = useStore();
    const { logoutUser } = useUserService();

    const navbarCollapsed = ref(true);
    const navbarApiShow = ref(false);

    function toggleNavbar() {
      navbarCollapsed.value = !navbarCollapsed.value;
    }

    function logout() {
      logoutUser();
      router.push('/');
    }

    const currentRoute = computed(() => {
      return useRouter().currentRoute.value.name;
    });

    const isCurrentRoute = (route: string): boolean => {
      return currentRoute.value === route;
    };

    return {
      isCurrentRoute,
      isLogged,
      user,
      navbarCollapsed,
      navbarApiShow,
      toggleNavbar,
      logout
    };
  }
});
</script>
