<template>
  <div class="container">
    <h1 class="title">Notice cookies</h1>
    <div class="flex">
      <div class="link-donnees-perso">
        <router-link to="/donnees-personnelles/politique">
          Politique générale de protection des données à caractère personnel
        </router-link>
      </div>
      <div class="link-donnees-perso">
        <router-link to="/donnees-personnelles/protection">
          Protection des données à caractère personnel
        </router-link>
      </div>
      <div class="link-donnees-perso"><router-link to="/cookies">Notice Cookies</router-link></div>
    </div>

    <template v-if="loading">
      <div class="text-center">
        <div class="spinner-border spinner-custom" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </template>

    <template v-else>
      <!-- eslint-disable vue/no-v-html -->
      <div class="markdown-body" v-html="cookies"></div>
      <!--eslint-enable-->
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

import marked from 'marked';
import sanitizeHtml from 'sanitize-html';

export default defineComponent({
  name: 'Cookies',
  setup() {
    const cookies = ref();
    const loading = ref(true);

    onMounted(async () => {
      const response = await fetch('../markdown/cookies/cookies.md');
      const data = await response.text();
      cookies.value = sanitizeHtml(marked(data));
      loading.value = false;
    });

    return { cookies, loading };
  }
});
</script>
