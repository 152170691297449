<template>
  <div class="container">
    <h1 class="title">Catalogue APIs</h1>
    <div class="flex-catalog">
      <CatalogCard
        v-for="api in apis"
        :path="`/service/${api.name}`"
        :title="api.title"
        :logo="`${api.name}.svg`"
        :axway-id="api.axwayId"
        :description="api.description"
        :key="api.name"
      ></CatalogCard>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import CatalogCard from '@/components/Api/CatalogCard.vue';
import Api from '@/models/Api';
import services from '@/assets/apis.json';

export default defineComponent({
  name: 'Catalogue',
  components: {
    CatalogCard
  },
  setup() {
    const apis = ref<Api[] | null>(null);

    apis.value = services;

    return { apis };
  }
});
</script>
