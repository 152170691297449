
import { defineComponent, ref, computed } from 'vue';
import router from '@/router';
import { useStore } from '@/store/store';
import { useRouter } from 'vue-router';
import { useUserService } from '@/services/useUserService';

export default defineComponent({
  name: 'Navbar',
  setup() {
    const { isLogged, user } = useStore();
    const { logoutUser } = useUserService();

    const navbarCollapsed = ref(true);
    const navbarApiShow = ref(false);

    function toggleNavbar() {
      navbarCollapsed.value = !navbarCollapsed.value;
    }

    function logout() {
      logoutUser();
      router.push('/');
    }

    const currentRoute = computed(() => {
      return useRouter().currentRoute.value.name;
    });

    const isCurrentRoute = (route: string): boolean => {
      return currentRoute.value === route;
    };

    return {
      isCurrentRoute,
      isLogged,
      user,
      navbarCollapsed,
      navbarApiShow,
      toggleNavbar,
      logout
    };
  }
});
