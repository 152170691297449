import { createRouter, createWebHistory } from 'vue-router';
import { useStore } from '@/store/store';

import Login from '@/components/User/Login.vue';

import Home from '@/views/Home.vue';
import Documentation from '@/views/Documentation.vue';
import Contact from '@/views/Contact.vue';
import ContactValid from '@/views/ContactValid.vue';

import DonneesPerso from '@/views/donnees/DonneesPerso.vue';
import DonneesPersoPolitique from '@/views/donnees/DonneesPersoPolitique.vue';
import DonneesPersoProtection from '@/views/donnees/DonneesPersoProtection.vue';

import MentionsLegales from '@/views/MentionsLegales.vue';
import Cookies from '@/views/Cookies.vue';

import Catalogue from '@/views/Catalogue.vue';

import Api from '@/components/Api/Api.vue';
import {useUserService} from "@/services/useUserService";

const { user, isLogged } = useStore();
const { logoutUser } = useUserService();

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/connexion',
      name: 'login',
      component: Login,
      meta: {
        hideForAuth: true
      }
    },
    {
      path: '/documentation',
      name: 'documentation',
      component: Documentation
    },
    {
      path: '/mentions-legales',
      name: 'mentions',
      component: MentionsLegales
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: Cookies
    },
    {
      path: '/donnees-personnelles',
      name: 'donnees',
      component: DonneesPerso
    },
    {
      path: '/donnees-personnelles/politique',
      name: 'politique',
      component: DonneesPersoPolitique
    },
    {
      path: '/donnees-personnelles/protection',
      name: 'protection',
      component: DonneesPersoProtection
    },
    {
      path: '/contact/:partenariat?',
      name: 'contact',
      component: Contact
    },
    {
      path: '/contact-valid',
      name: 'contactvalid',
      component: ContactValid
    },
    {
      path: '/catalogue',
      name: 'catalogue',
      component: Catalogue
    },
    {
      path: '/service/:api',
      name: 'service',
      component: Api
    }
  ]
});

router.beforeEach((to, _, next) => {
  if (!to.matched.length) {
    router.push('/');
  }

  if((!isLogged.value && user.value != null) || (isLogged.value && user.value == null)) {
    logoutUser();
    router.push('/');
  }

  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (isLogged.value) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
