<template>
  <template v-if="error">
    <Message message="Impossible de charger le récapitulatif." type="warning"></Message>
  </template>

  <div class="stats-recap">
    <div class="stats-recap-card">
      <p class="stats-recap-card-title">Semaine en cours</p>
      <p>{{ numberCallsForCurrentWeek }} appels</p>
    </div>

    <div class="stats-recap-card">
      <p class="stats-recap-card-title">Mois en cours</p>
      <p>{{ numberCallsForCurrentMonth }} appels</p>
    </div>

    <div class="stats-recap-card">
      <p class="stats-recap-card-title">Année en cours</p>
      <p>{{ numberCallsForCurrentYear }} appels</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, toRefs } from 'vue';

import { useChart } from '@/composables/useChart';
import Message from '@/components/Static/Message.vue';

export default defineComponent({
  name: 'RecapMessages',
  components: {
    Message
  },
  props: {
    service: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const { fetchDataChart, formatDateToString, generateBeginDate, generateEndDate } = useChart();

    const state = reactive({
      numberCallsForCurrentWeek: 0,
      numberCallsForCurrentMonth: 0,
      numberCallsForCurrentYear: 0,
      error: false
    });

    onMounted(async () => {
      state.error = false;
      getNumberCallsForCurrentWeek();
      getNumberCallsForCurrentMonth();
      getNumberCallsForCurrentYear();
    });

    const getNumberCallsForCurrentWeek = async () => {
      const date = new Date();
      const firstDay = formatDateToString(new Date(date.setDate(date.getDate() - date.getDay())));
      const lastDay = formatDateToString(
        new Date(date.setDate(date.getDate() - date.getDay() + 7))
      );

      const { data, error } = await fetchDataChart(
        generateBeginDate(firstDay),
        generateEndDate(lastDay),
        props.service
      );

      state.error = error;
      state.numberCallsForCurrentWeek = data.values.reduce((a: number, b: number) => a + b, 0);
    };

    const getNumberCallsForCurrentMonth = async () => {
      const date = new Date();
      const firstDay = formatDateToString(new Date(date.getFullYear(), date.getMonth(), 1));
      const lastDay = formatDateToString(new Date(date.getFullYear(), date.getMonth() + 1, 0));

      const { data, error } = await fetchDataChart(
        generateBeginDate(firstDay),
        generateEndDate(lastDay),
        props.service
      );

      state.error = error;
      state.numberCallsForCurrentMonth = data.values.reduce((a: number, b: number) => a + b, 0);
    };

    const getNumberCallsForCurrentYear = async () => {
      const date = new Date();
      const firstDay = formatDateToString(new Date(date.getFullYear(), 0, 1));
      const lastDay = formatDateToString(new Date(date.getFullYear(), 11, 31));

      const { data, error } = await fetchDataChart(
        generateBeginDate(firstDay),
        generateEndDate(lastDay),
        props.service
      );

      state.error = error;
      state.numberCallsForCurrentYear = data.values.reduce((a: number, b: number) => a + b, 0);
    };

    return { ...toRefs(state) };
  }
});
</script>
