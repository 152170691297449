import StatsAxway from '@/models/StatsAxway';
import { useAxwayService } from '@/services/useAxwayService';

export function useChart() {
  const { fetchAxwayStats } = useAxwayService();

  const fetchDataChart = async (
    selectedBeginDate: string,
    selectedEndDate: string,
    service: string
  ) => {
    let error = false;

    const { data, status } = await fetchAxwayStats(
      'numMessages',
      selectedBeginDate,
      selectedEndDate,
      service
    );

    if (status === 500) {
      error = true;
    }

    return { data, error };
  };

  const formatDateToString = (date: Date): string => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };

  const generateBeginDate = (dateStr: string) => {
    // On enlève un jour pour gérer les problèmes de fuseaux horaires
    const beginDate = new Date(dateStr);
    beginDate.setDate(beginDate.getDate() - 1);

    return `${formatDateToString(beginDate)}T23%3A00%3A00Z`;
  };

  const generateEndDate = (dateStr: string) => {
    return `${dateStr}T22%3A00%3A00Z`;
  };

  const generateLineChartCategories = (data: StatsAxway): number[] => {
    // Si on reçoit un intervalle de 5 minutes on tranforme les valeurs pour avoir un intervalle en heure
    if (data.interval === 300000) {
      data.values = transformValues(data.values);
      data.interval = 3600000;
    }

    // On ajoute le premier point de départ au tableau
    const categories: number[] = [data.startTimestamp];

    data.values.forEach((_: number, index: number) => {
      if (index !== 0) {
        // On rajoute la durée de l'intervalle à tous les points sauf au premier
        categories.push(categories[index - 1] + data.interval);
      }
    });

    return categories;
  };

  const transformValues = (values: number[]): number[] => {
    const newValues: number[] = [];

    values.forEach((_: number, index: number) => {
      if (index % 12 === 0) {
        const sum = values.slice(index, index + 12).reduce((a: number, b: number) => a + b, 0);
        newValues.push(sum);
      }
    });

    return newValues;
  };

  return {
    fetchDataChart,
    generateLineChartCategories,
    formatDateToString,
    generateBeginDate,
    generateEndDate
  };
}
