import { useStore } from '@/store/store';
import axios from 'axios';

export function useAxwayService() {
  const { user } = useStore();

  const fetchAxwaySwagger = async (name: string) => {
    const headers = { userCookie: user.value?.cookie };
    const { data, status } = await axios.get(`/rest/api-information/swagger?apiName=${name}`, {
      headers
    });

    return { data, status };
  };

  const fetchAxwayStats = async (metricType: string, from: string, to: string, service: string) => {
    const headers = { userCookie: user.value?.cookie };
    const {
      data,
      status
    } = await axios.get(
      `/rest/api-information/stats/${metricType}?from=${from}&to=${to}&service=${service}&organization=${user.value?.organization.name}`,
      { headers }
    );

    return { data, status };
  };

  return {
    fetchAxwaySwagger,
    fetchAxwayStats
  };
}
