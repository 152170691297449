
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';

import Message from '@/components/Static/Message.vue';
import {ErrorMessage, Field, Form} from 'vee-validate';
import {useContactService} from '@/services/useContactService';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from '@/store/store';
import {useReCaptcha} from 'vue-recaptcha-v3';

import apis from '@/assets/apis.json';
import '@/formsRules';

export default defineComponent({
  name: 'Contact',
  components: {
    ErrorMessage,
    Field,
    Form,
    Message
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {isLogged, user} = useStore();
    const {sendToJira} = useContactService();
    const reCaptcha = useReCaptcha();

    const validation = ref(false);
    let error = ref(false);
    let selectedType = ref('');
    let optionsProduct = ref<string[]>();
    const optionsType = [
      {text: 'Question', value: 'Question'},
      {text: 'Remarque', value: 'Remarque'},
      {text: 'Suggestion', value: 'Suggestion'},
      {text: 'Partenariat', value: 'Partenariat'},
      {text: 'Autre', value: 'Autre'}
    ];
    const optionsSupportType = [
      {text: 'Technique', value: 'Technique'},
      {text: 'Fonctionnel', value: 'Fonctionnel'}
    ];

    onMounted(async () => {
      window.scroll(1, 1);
      optionsProduct.value = apis.map((a) => a.title);

      if (route.params.partenariat === 'partenariat') {
        selectedType.value = 'Partenariat';
      }

      await reCaptcha?.recaptchaLoaded();
      reCaptcha?.instance.value?.showBadge();
    });

    onUnmounted(() => {
      reCaptcha?.instance.value?.hideBadge();
    });

    const sendContactToJira = async (token: string, values: any, {resetForm}: any) => {
      validation.value = false;
      error.value = false;

      let support = '';
      let phone = '';
      if (user != null && selectedType.value === 'Support technique') {
        support = `*Type de support :* ${values.support}\n*Bloquant :* ${values.blocage}`;
      }
      if (user == null) {
        phone = `*Téléphone :* ${values.telephone}`;
      }

      const title = `[Portail API] ${values.type}`;
      const description = `*Nom :* ${values.nom}\n*Email :* ${values.email}\n*Société :* ${values.societe}\n*Produit :* ${values.produit}\n*Message :* ${values.message}\n${phone}\n${support}`;

      const responseJira = await sendToJira(title, description, token);

      if (responseJira.ok) {
        validation.value = true;
        //resetForm();
        await router.push('/contact-valid');
      } else {
        error.value = true;
      }
    };

    const sendForm = async (values: any, {resetForm}: any) => {
      await reCaptcha!.recaptchaLoaded();
      const token = await reCaptcha!.executeRecaptcha('login');

      await sendContactToJira(token, values, resetForm);
    };

    return {
      user,
      isLogged,
      sendForm,
      validation,
      error,
      selectedType,
      optionsProduct,
      optionsType,
      optionsSupportType,
      route
    };
  }
});
