import { configure, defineRule } from 'vee-validate';
import { email, min, required } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';

defineRule('required', required);
defineRule('min', min);
defineRule('email', email);

configure({
  validateOnInput: true,
  generateMessage: localize('fr', {
    messages: {
      required: (context) => `Le champ ${context.field} est obligatoire.`,
      min: 'Le champ {field} doit avoir au moins 0:{min} caractères.',
      email: "Cette adresse email n'est pas valide."
    }
  })
});
