import { createApp } from 'vue';

import App from './App.vue';
import router from '@/router';

import VueApexCharts from 'vue3-apexcharts';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Particules from 'particles.vue3';
import axios from 'axios';
import VCalendar from 'v-calendar';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faIdCard,
  faBuilding,
  faSignOutAlt,
  faLock,
  faQuestionCircle,
  faArrowRight,
  faHome,
  faBook,
  faChartLine,
  faCookieBite
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faUser,
  faIdCard,
  faBuilding,
  faSignOutAlt,
  faLock,
  faQuestionCircle,
  faArrowRight,
  faHome,
  faBook,
  faChartLine,
  faCookieBite
);

import { useStore } from '@/store/store';
const { deleteUser } = useStore();

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.data.detail;
    if (status === '401 Unauthorized') {
      deleteUser();
      router.push(router.currentRoute.value);
    }
    return Promise.reject(error);
  }
);

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(VueApexCharts)
  .use(Particules)
  .use(VCalendar, {})
  .use(VueReCaptcha, {
    siteKey: '6LcxTDseAAAAANo5QAEgH-ETSn8krLggBw3ciIqH',
    loaderOptions: {
      autoHideBadge: true
    }
  })
  .mount('#app');
