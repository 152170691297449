<template>
  <div class="container">
    <div class="login d-flex flex-column justify-content-center align-items-center">
      <center><h1 class="title">Connexion</h1></center>

      <div class="card" style="width: 30rem">
        <template v-if="error">
          <Message message="Impossible d'effectuer la connexion" type="warning"></Message>
        </template>

        <div class="card-body">
          <form @submit.prevent="login()">
            <div class="mb-3">
              <input
                type="username"
                class="form-control"
                id="username"
                aria-describedby="usernameHelp"
                v-model="username.value"
                placeholder="&#xF007; Identifiant"
              />
            </div>

            <div class="mb-3">
              <input
                type="password"
                class="form-control"
                id="password"
                v-model="password.value"
                placeholder="&#xF023; Mot de passe"
              />
            </div>

            <div class="form-text d-md-flex justify-content-md-end">
              <RouterLink class="login-ask" to="/contact/partenariat">Demander un accès</RouterLink>
            </div>

            <div class="d-md-flex justify-content-md-center">
              <button
                :disabled="!formMeta.valid"
                type="submit"
                class="btn button-primary login-btn"
                aria-describedby="loginHelp"
              >
                <template v-if="loading">
                  <div class="text-center">
                    <div class="spinner-border login-spinner" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </template>
                <template v-else>Se connecter</template>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, reactive, ref} from 'vue';
import router from '@/router';

import { useField, useForm } from 'vee-validate';
import { useUserService } from '@/services/useUserService';
import { useReCaptcha } from 'vue-recaptcha-v3';
import Message from '@/components/Static/Message.vue';
import '@/formsRules';

export default defineComponent({
  name: 'Login',
  components: {
    Message
  },
  setup() {
    const { loginUser } = useUserService();
    const reCaptcha = useReCaptcha();

    const error = ref(false);
    const loading = ref(false);

    const { meta: formMeta, values: formValues } = useForm();
    const username = reactive(useField('username', { required: true }));
    const password = reactive(useField('password', { required: true }));

    onMounted(async () => {
      await reCaptcha?.recaptchaLoaded();
      reCaptcha?.instance.value?.showBadge();
    });

    onUnmounted(() => {
      reCaptcha?.instance.value?.hideBadge();
    });

    async function login() {
      error.value = false;
      loading.value = true;

      await reCaptcha!.recaptchaLoaded();
      const token = await reCaptcha!.executeRecaptcha('login');

      const errorLogin = await loginUser(formValues.username, formValues.password, token);

      if (!errorLogin) {
        router.push('/');
      } else {
        error.value = true;
      }

      loading.value = false;
    }

    return { username, password, formMeta, login, error, loading };
  }
});
</script>
