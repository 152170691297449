export function useContactService() {
  const sendToJira = (title: string, description: string, token: string) => {
    const url = '/rest/contact';

    const values = {
      title,
      description,
      token
    };

    return fetch(url, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });
  };

  return { sendToJira };
}
