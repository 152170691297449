
import { defineComponent } from 'vue';

import { useStore } from '@/store/store';

export default defineComponent({
  name: 'CookieLaw',
  setup() {
    const { acceptedCookies, storeAcceptedCookies } = useStore();

    return { acceptedCookies, storeAcceptedCookies };
  }
});
