
import { defineComponent, onMounted, PropType, ref } from 'vue';

import { useSwagger } from '@/composables/useSwagger';
import Message from '@/components/Static/Message.vue';

import Api from '@/models/Api';

export default defineComponent({
  name: 'DocumentationApi',
  components: {
    Message
  },
  props: {
    api: {
      type: Object as PropType<Api>,
      required: true
    }
  },
  setup(props) {
    const { fetchSwagger } = useSwagger();
    const error = ref(false);

    onMounted(() => {
      try {
        fetchSwagger(props.api.axwayName)
          .then((data: any) => {
            let redoc = document.createElement('script');
            redoc.innerText = `
                Redoc.init(${JSON.stringify(data.data)},
                { suppressWarnings: true, noAutoAuth: true, scrollYOffset: 100, pathInMiddlePanel: true },
                document.getElementById('redoc'))`;
            document.head.appendChild(redoc);
          })
          .catch(() => {
            error.value = true;
          });
      } catch (_) {
        error.value = true;
      }
    });

    return { error };
  }
});
