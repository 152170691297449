<template>
  <div class="catalog-card shadow-move">
    <div class="catalog-card-container">
      <RouterLink :to="path">
        <div class="case row">
          <div class="col-3">
            <p>
              <img
                class="img-fluid img-100"
                :src="require(`@/assets/images/apis/${logo}`).default"
              />
            </p>
          </div>
          <div class="col-9 pt-3">
            <h3 class="catalog-card-subtitle">{{ title }}</h3>
          </div>
        </div>
        <p class="catalog-card-text">
          <small>
            {{ description }}
          </small>
        </p>
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CatalogCard',
  props: {
    path: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    axwayId: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
});
</script>
