
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CatalogCard',
  props: {
    path: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    logo: {
      type: String,
      required: true
    },
    axwayId: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
});
